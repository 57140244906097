import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
import { getKycDocWithVehicle } from '../../KycDoc/store';

export const getVehicle = createAsyncThunk('vehicleSlice/getVehicle', async () => {
    try {
        let response = await axios.get('/vehicle', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleOption = createAsyncThunk('vehicleSlice/getVehicleOption', async () => {
    try {
        // showLoader(true);
        let response = await axios.get('/vehicle?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAppUserOption = createAsyncThunk('vehicleSlice/getAppUserOption', async () => {
    try {
        // showLoader(true);
        let response = await axios.get('/app-user/option', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createVehicle = createAsyncThunk('vehicleSlice/createVehicle', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/vehicle', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle created successfully.');
        dispatch(getVehicle());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicle = createAsyncThunk('vehicleSlice/updateVehicle', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle updated successfully.');
        dispatch(getKycDocWithVehicle(data?.id));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteVehicle = createAsyncThunk('vehicleSlice/deleteVehicle', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/vehicle/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle deleted successfully');
        dispatch(getVehicleOption());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleStatus = createAsyncThunk('vehicleSlice/updateVehicleStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getVehicle());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const vehicleSlice = createSlice({
    name: 'vehicleSlice',
    initialState: {
        data: [],
        option: [],
        appUserOption: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicle.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVehicleOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
        builder.addCase(getAppUserOption.fulfilled, (state, action) => {
            state.appUserOption = action.payload;
            return;
        });
    }
});

export default vehicleSlice.reducer;
