import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getVehicleModel = createAsyncThunk('vehicleModelSlice/getVehicleModel', async () => {
    try {
        let response = await axios.get('/vehicle-model', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleModelOptionById = createAsyncThunk('vehicleModelSlice/getVehicleModelOptionById', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/client/vehicle-model/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getVehicleModelOptionByIdForSpecification = createAsyncThunk(
    'vehicleModelSlice/getVehicleModelOptionByIdForSpecification',
    async (id) => {
        try {
            showLoader(true);
            let response = await axios.get('/vehicle-model/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const getVehicleModelOption = createAsyncThunk('vehicleModelSlice/getVehicleModelOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/vehicle-model?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createVehicleModel = createAsyncThunk('vehicleModelSlice/createVehicleModel', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/vehicle-model', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle model created successfully.');
        dispatch(getVehicleModel());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleModel = createAsyncThunk('vehicleModelSlice/updateVehicleModel', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-model/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Vehicle model updated successfully.');
        dispatch(getVehicleModel());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteVehicleModel = createAsyncThunk('vehicleModelSlice/deleteVehicleModel', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/vehicle-model/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle model deleted successfully');
        dispatch(getVehicleModel());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleModelStatus = createAsyncThunk('vehicleModelSlice/updateVehicleModelStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-model/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getVehicleModel());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const vehicleModelSlice = createSlice({
    name: 'vehicleModelSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicleModel.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVehicleModelOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default vehicleModelSlice.reducer;
