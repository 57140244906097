import React from 'react';

// theme config import
import themeConfig from '@configs/themeConfig';
// ** Custom Hooks
import { useSkin } from '@hooks/useSkin';
import lightLogo from '../../../assets/images/logo/black-logo-marun.svg';
export const LoaderComponent = () => {
    // ** Hooks
    const { skin } = useSkin();
    return (
        <div className="loader-component">
            <div id="loader" className="fallback-spinner app-loader" style={{ display: 'none', width: '100vw' }}>
                <img className="fallback-logo" src={skin === 'dark' ? themeConfig.app.appLogo : lightLogo} height="60px" alt="logo" />
                <div className="loading">
                    <div className="effect-1 effects"></div>
                    <div className="effect-2 effects"></div>
                    <div className="effect-3 effects"></div>
                </div>
            </div>
        </div>
    );
};

var pendingRequest = 0;
const showLoader = (loading) => {
    const loader = document.getElementById('loader');
    if (loader) {
        if (loading === true) {
            pendingRequest++;
            loader.classList.add('d-flex');
        } else {
            pendingRequest--;
            if (pendingRequest <= 0) {
                loader.classList.remove('d-flex');
            }
        }
    }
};

export default showLoader;
