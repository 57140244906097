import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';
// import themeConfig from '../../../../configs/themeConfig';
import themeConfig from '../../../configs/themeConfig';

const initialTakeOwnerShipfun = () => {
    const item = window.localStorage.getItem('takeOwnerShip');

    return item ? JSON.parse(item) : themeConfig.layout.takeOwnerShip;
};
export const getTransferOwnership = createAsyncThunk('dashboardSlice/getTransferOwnership', async () => {
    try {
        let response = await axios.get('/client/transfer-vehicle-ownership', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createTransferOwnership = createAsyncThunk('dashboardSlice/createTransferOwnership', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/client/transfer-vehicle-ownership', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle Category created successfully.');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        data: [],
        options: [],
        selectedRole: {},
        module: [],
        initialTakeOwnerShip: initialTakeOwnerShipfun()
    },
    reducers: {
        handleTackOwherShip: (state, action) => {
            state.initialTakeOwnerShip = action.payload;
            localStorage.setItem('takeOwnerShip', JSON.stringify(action.payload));
        }
    }
});

export const { handleTackOwherShip } = dashboardSlice.actions;

export default dashboardSlice.reducer;
