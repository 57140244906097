import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// Loader Components
import showLoader from '@components/loader';

export const getOverSpeedData = createAsyncThunk('familySlice/getOverSpeedData', async () => {
    try {
        let response = await axios.get('/client/vehicle/overspeeding', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getTrips = createAsyncThunk('historySlice/getTrips', async (data) => {
    try {
        const query = queryString.stringify(data);

        showLoader(true);
        let response = await axios.get('/vehicle-trips?' + `&${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        // return Promise.resolve(response.data.data.rows);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSingleTrip = createAsyncThunk('historySlice/getSingleTrip', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/vehicle-trips/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const historySlice = createSlice({
    name: 'historySlice',
    initialState: {
        data: [],
        tripData: [],
        option: [],
        overSpeedData: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTrips.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getSingleTrip.fulfilled, (state, action) => {
            state.tripData = action.payload;
            return;
        });
        builder.addCase(getOverSpeedData.fulfilled, (state, action) => {
            state.overSpeedData = action.payload;
            return;
        });
    }
});

export default historySlice.reducer;
