import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getOther = createAsyncThunk('otherSlice/getOther', async () => {
    try {
        let response = await axios.get('/client/vehicle/app-user', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createOther = createAsyncThunk('otherSlice/createOther', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/client/vehicle-invitation', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Family created successfully.');
        dispatch(getFamily());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteOther = createAsyncThunk('otherSlice/deleteOther', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/client/guest/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Family deleted successfully');
        // dispatch(getEmergencyContact());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const otherSlice = createSlice({
    name: 'otherSlice',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOther.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
    }
});

export default otherSlice.reducer;
