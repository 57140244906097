import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

// Loader Components
import showLoader from '@components/loader';

export const getVehicleType = createAsyncThunk('vehicleTypeSlice/getVehicleType', async () => {
    try {
        let response = await axios.get('/vehicle-type', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleTypeOption = createAsyncThunk('vehicleTypeSlice/getVehicleTypeOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/vehicle-type?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVehicleTypeOptionWithQuery = createAsyncThunk('vehicleTypeSlice/getVehicleTypeOptionWithQuery', async (data) => {
    try {
        const query = queryString.stringify(data);

        showLoader(true);
        let response = await axios.get('/vehicle-type?option=true' + `&${query}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createVehicleType = createAsyncThunk('vehicleTypeSlice/createVehicleType', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/vehicle-type', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle type created successfully.');
        dispatch(getVehicleType());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleType = createAsyncThunk('vehicleTypeSlice/updateVehicleType', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-type/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle type updated successfully.');
        dispatch(getVehicleType());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteVehicleType = createAsyncThunk('vehicleTypeSlice/deleteVehicleType', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/vehicle-type/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vehicle type deleted successfully');
        dispatch(getVehicleType());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVehicleTypeStatus = createAsyncThunk('vehicleTypeSlice/updateVehicleTypeStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/vehicle-type/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getVehicleType());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const vehicleTypeSlice = createSlice({
    name: 'vehicleTypeSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVehicleType.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVehicleTypeOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default vehicleTypeSlice.reducer;
