import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getKycDoc = createAsyncThunk('kycDocSlice/getKycDoc', async () => {
    try {
        let response = await axios.get('/kyc-doc', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getKycDocWithVehicle = createAsyncThunk('kycDocSlice/getKycDocWithVehicle', async (id) => {
    try {
        let response = await axios.get('/client/vehicle-doc', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getKycDocOption = createAsyncThunk('kycDocSlice/getKycDocOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/kyc-doc?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createKycDoc = createAsyncThunk('kycDocSlice/createKycDoc', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/kyc-doc', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Kyc doc created successfully.');
        dispatch(getKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateKycDoc = createAsyncThunk('kycDocSlice/updateKycDoc', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/kyc-doc/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Kyc doc updated successfully.');
        dispatch(getKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteKycDoc = createAsyncThunk('kycDocSlice/deleteKycDoc', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/kyc-doc/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Kyc doc deleted successfully');
        dispatch(getKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateKycDocStatus = createAsyncThunk('kycDocSlice/updateKycDocStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/kyc-doc/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getKycDoc());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const kycDocSlice = createSlice({
    name: 'kycDocSlice',
    initialState: {
        data: [],
        option: [],
        dataWithVehicle: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getKycDoc.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getKycDocOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
        builder.addCase(getKycDocWithVehicle.fulfilled, (state, action) => {
            state.dataWithVehicle = action.payload;
            return;
        });
    }
});

export default kycDocSlice.reducer;
