// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import tabs from './tabs';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import moduleSlice from '../views/Setup/ModuleManage/store';
import appUsers from '../views/AppUser/store';
import vehicleType from '../views/Master/VehicleType/store';
import vehicleBrand from '../views/Master/VehicleBrand/store';
import vehicleModel from '../views/Master/VehicleModel/store';
import vehicle from '../views/Master/Vehicle/store';
import kycDoc from '../views/Master/KycDoc/store';
import vehicleKycDoc from '../views/Master/VehicleKycDoc/store';
import challanType from '../views/Master/ChallanType/store';
import challanRule from '../views/Master/ChallanRule/store';
import speedConfiguration from '../views/Master/SpeedConfiguration/store';
import autoInsurance from '../views/AutoInsurance/store';
import sos from '../views/Master/Sos/store';
import insuranceOffer from '../views/Master/InsuranceOffer/store';
import vehicleSpecification from '../views/Master/VehicleSpecification/store';
import emergencyContact from '../views/EmergencyContact/store';

import faqCategorySlice from '../views/Master/FaqCategory/store';
import helpDeskActiveClosedSlice from '../views/Help/HelpDesk/store';

import purpose from '../views/Master/Purpose/store';
import vehicleCategory from '../views/Master/Category/store';
import history from '../views/History/store';
import mapSetting from '../views/MapSetting/store';
import dashboardSlice from '../views/Dashboard/store';
import email from '../views/Notifications/store';

import familySlice from '../views/ActiveUsers/Family/store';
import otherSlice from '../views/ActiveUsers/Others/store';
import driverSlice from '../views/ActiveUsers/Drivers/store';

import liveLocationSlice from '../views/LiveTracking/store/index';

const rootReducer = {
    auth,
    navbar,
    tabs,
    layout,
    permission,
    users,
    appUsers,
    roles,
    moduleSlice,
    vehicleType,
    vehicleBrand,
    vehicleModel,
    vehicle,
    kycDoc,
    vehicleKycDoc,
    challanType,
    challanRule,
    speedConfiguration,
    autoInsurance,
    sos,
    insuranceOffer,
    vehicleSpecification,
    emergencyContact,
    faqCategorySlice,
    helpDeskActiveClosedSlice,
    purpose,
    vehicleCategory,
    email,
    history,
    mapSetting,
    dashboardSlice,
    familySlice,
    otherSlice,
    driverSlice,
    liveLocationSlice
};

export default rootReducer;
