import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getFamily = createAsyncThunk('familySlice/getFamily', async () => {
    try {
        let response = await axios.get('/client/vehicle/user?type=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getRemainingFamily = createAsyncThunk('familySlice/getRemainingFamily', async () => {
    try {
        let response = await axios.get('/client/vehicle-invitation?type=1&status=0', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createFamily = createAsyncThunk('familySlice/createFamily', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/client/vehicle-invitation', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Family created successfully.');
        dispatch(getFamily());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteFamily = createAsyncThunk('familySlice/deleteFamily', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/client/vehicle/family/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Family deleted successfully');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteFamilyInvitation = createAsyncThunk('familySlice/deleteFamilyInvitation', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/client/vehicle-invitation/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Family deleted successfully');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const resendFamilyInvitation = createAsyncThunk('familySlice/resendFamilyInvitation', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.get('/client/resend-invitation/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Family Resend Invitation successfully');
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const familySlice = createSlice({
    name: 'familySlice',
    initialState: {
        data: [],
        remainingFamily: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getFamily.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getRemainingFamily.fulfilled, (state, action) => {
            state.remainingFamily = action.payload;
            return;
        });
    }
});

export default familySlice.reducer;
