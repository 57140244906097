import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getEmergencyContact = createAsyncThunk('emergencyContactSlice/getEmergencyContact', async () => {
    try {
        let response = await axios.get('/emergency-contact', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getEmergencyContactOption = createAsyncThunk('emergencyContactSlice/getEmergencyContactOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/emergency-contact?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmergencyContact = createAsyncThunk('emergencyContactSlice/createEmergencyContact', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/client/emergency-contact', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Emergency contact created successfully.');
        // dispatch(getEmergencyContact());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmergencyContact = createAsyncThunk('emergencyContactSlice/updateEmergencyContact', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/client/emergency-contact/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Emergency contact updated successfully.');
        // dispatch(getEmergencyContact());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteEmergencyContact = createAsyncThunk('emergencyContactSlice/deleteEmergencyContact', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/client/emergency-contact/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Emergency contact deleted successfully');
        // dispatch(getEmergencyContact());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.log(err?.response?.data?.message || 'Something went wrong!');
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateEmergencyContactStatus = createAsyncThunk(
    'emergencyContactSlice/updateEmergencyContactStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/client/emergency-contact/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getEmergencyContact());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            console.log(err?.response?.data?.message || 'Something went wrong!');
            // toast.err(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const emergencyContactSlice = createSlice({
    name: 'emergencyContactSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmergencyContact.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getEmergencyContactOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default emergencyContactSlice.reducer;
